<template>
	<v-container class="font-size-24">
		<v-row style="height: 570px;overflow-x: hidden;overflow-y: auto;">
			<v-col>
				<div class="mr-8 Causal_tree">
					<ReasonTree id="reasion-tree" v-model="triz.reasonTreeData" @change="change" />
				</div>
			</v-col>
			
		</v-row>
		<v-row align="center" justify="center" style="height: 20px;">
			图 系统的因果链关系图 
			<span style="right: 70px;position: absolute;color: #1976d2;">tps:点击“节点”选择关键缺点</span>
		</v-row>
	</v-container>
</template>

<script>
	import ReasonTree from '@/components/new_triz/ReasonTree.vue'
	import InnerTextField from '@/components/new_triz/InnerTextField'
	import ReasonTable from '@/components/new_triz/ReasonTable'

	export default {
		components: {
			ReasonTree,
			InnerTextField,
			ReasonTable,
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
		},
		model: {
			prop: 'triz',
			event: 'change',
		},
		data() {
			return {
				// triz: this.triz,
			}
		},

		mounted() {
		},
		methods: {
			change() {
				console.log('change')
				this.$emit('change', this.triz)
			},
			reasonChange(list) {
				console.log('reasonChange')
				this.triz.reasons = list
				this.change()
			},
			flatTreeData(tree) {
				const result = []
				if (!tree.children) return
				this.flatTree(tree.children, result)
				return result.filter((ele) => ele.selected)
			},
			flatTree(children, result) {
				for (let i = 0; i < children.length; i++) {
					const child = children[i]
					result.push(child)
					if (child.children) {
						this.flatTree(child.children, result)
					}
				}
			},
		},
	}
</script>
<style lang="scss">
	.Causal_tree {
		.v-btn:not(.v-btn--round).v-size--default {
			min-width: 10px;
			padding: 18px;
			border-radius: 25px;
		}
	}
</style>