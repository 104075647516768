<template>
	<div class="font-size-24">
		<template>
			<v-row style="width: 100%;height: 50px;z-index: 999;">
				<v-col cols="10" style="padding: 0px  2px;">
					<v-btn-toggle v-model="btn_toggle" color="primary" variant="outlined">
						<v-btn v-for="(search, i) in triz.searches" :key="`searches-${i}-btn`">
							<span>导向{{i+1}}</span>
						</v-btn>
						<v-icon color="#1976d2" style="width: 25px;height: 25px;margin: 10px 2px;" size="x-large"
							@click="addSearch()">mdi-plus-circle-outline</v-icon>
						<v-icon color="#1976d2" style="width: 25px;height: 25px;margin: 10px 2px;" size="x-large"
							@click="removeSearch(btn_toggle)">mdi-minus-circle-outline</v-icon>
					</v-btn-toggle>
				</v-col>
				<v-col cols="2" style="padding: 0px  2px;" align="center" justify="center">
					<v-btn dense class="ma-2" tile outlined color="#1976d2" style="border-radius: 25px"
						@click="addSolution(triz.searches[btn_toggle])">
						添加解决方案
					</v-btn>
				</v-col>
			</v-row>
		</template>
		<template v-for="(search, i) in this.triz.searches" v-if='i==btn_toggle'>
			<v-container :key="`searches-${i}`">
				<v-row style="margin-top: 10px;">
					<p>1.识别要解决的关键问题</p>
					<editor :toref="`step1-${i}`" :inputref="`step1-${i}Input`" style="width: 100%;"
						toplaceholder='请输入' v-model="search.step1" @blur="change" />
				</v-row>
				<v-row>
					<p>2.明确要执行的特定功能</p>
					<editor :toref="`step2-${i}`" :inputref="`step2-${i}Input`" style="width: 100%;"
						toplaceholder='请输入' v-model="search.step2" @blur="change" />
				</v-row>
				<v-row>
					<p>3.制定所需的参数</p>
					<editor :toref="`step3-${i}`" :inputref="`step3-${i}Input`" style="width: 100%;"
						toplaceholder='请输入' v-model="search.step3" @blur="change" />
				</v-row>
				<v-row>
					<p>4.将功能一般化</p>
					<editor :toref="`step4-${i}`" :inputref="`step4-${i}Input`" style="width: 100%;"
						toplaceholder='请输入' v-model="search.step4" @blur="change" />
				</v-row>
				<v-row>
					<p>5.在相关和非相关行业，识别执行类似功能的其他技术</p>
					<editor :toref="`step5-${i}`" :inputref="`step5-${i}Input`" style="width: 100%;"
						toplaceholder='请输入' v-model="search.step5" @blur="change" />
				</v-row>
				<v-row>
					<p>6.基于项目需求和条件限制，选择最适合执行期望功能的技术</p>
					<editor :toref="`step6-${i}`" :inputref="`step6-${i}Input`" style="width: 100%;"
						toplaceholder='请输入' v-model="search.step6" @blur="change" />
				</v-row>
				<v-row>
					<p>7.识别和解决适应和实施所选技术的次生问题</p>
					<editor :toref="`step7-${i}`" :inputref="`step7-${i}Input`" style="width: 100%;"
						toplaceholder='请输入' v-model="search.step7" @blur="change" />
				</v-row>
					

				<template v-for="(solution, j) in search.solutions">
					<v-row :key="`selectedPrinciple-${j}`"> </v-row>
					<v-row :key="`delete-${j}`" align="center">
						<strong>基于功能向导的解决方案{{ j + 1 }}</strong>
						<v-btn dense class="ma-2" tile outlined color="error" style="border-radius: 25px"
							@click="removeSolution(search, j)">
							<v-icon left>mdi-minus</v-icon> 删除
						</v-btn>
					</v-row>
					<v-row :key="`detail-${j}`">
						<v-col style="padding: 12px 12px 12px 0">
							<editor :toref="`solutions-${j}`" :inputref="`solutions-${j}Input`"
								toplaceholder='请输入解决方案' v-model="solution.solutionDetail" @blur="change" />
						</v-col>
					</v-row>
				</template>
			</v-container>
		</template>
	</div>
</template>

<script>
	import InnerTextField from '@/components/InnerTextField'
	import editor from '@/components/editor/editor.vue'

	export default {
		components: {
			InnerTextField,
			editor,
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
		},
		model: {
			prop: 'triz',
			event: 'change',
		},
		data() {
			return {
				btn_toggle: 0,
				searchDialog: false,
				// // triz: this.triz,
			}
		},
		computed: {
		},
		mounted() {
			if(this.triz.searches.length==0){
				this.addSearch()
			}
		},
		methods: {
			change() {
				this.$emit('change', this.triz)
			},
			showSearchDialog() {
				this.searchDialog = true
			},
			addSearch() {
				const id = `search-${new Date().getTime()}`
				this.triz.searches.push({
					id,
					solutions: [],
				})
				this.btn_toggle=this.triz.searches.length-1
				this.change()
			},
			removeSearch(i) {
				const search = this.triz.searches[i]
				search.solutions.forEach((solution) => {
					this.triz.solutionScores.splice(
						this.triz.solutionScores.findIndex((ele) => ele.id === solution.id),
						1
					)
				})
				this.triz.searches.splice(i, 1)
				if(i>0){
					this.btn_toggle=i-1
				}else{
					this.btn_toggle=0
				}
				this.change()
			},
			addSolution(search) {
				const id = `guide-${new Date().getTime()}`
				search.solutions.push({
					id,
					solutionDetail: '',
				})
				this.triz.solutionScores.push({
					id,
				})
				this.change()
			},
			removeSolution(search, i) {
				const [{
					id
				}] = search.solutions.splice(i, 1)
				this.triz.solutionScores.splice(
					this.triz.solutionScores.findIndex((ele) => ele.id === id),
					1
				)
				this.change()
			},
		},
	}
</script>
<style lang="scss">
	.searchBtn {
		margin-bottom: 10px;
	}
</style>