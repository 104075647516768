<template>
	<v-container class="special_p font-size-24"  @click="openInven">
		<v-row>
			<p>将此技术矛盾转化为物理矛盾，即同一个参数具有相反的并且合乎情理的需求。</p>
		</v-row>
		<template v-for="(physical, i) in conflict.physicalConflicts">
			<v-row :key="`title-${i}`" align="center">
				<p>
					<strong>{{ i + 1 }}.物理矛盾{{ i + 1 }}</strong>
					<!-- <v-btn dense class="ma-2" tile outlined color="error" @click="removePhysical(i)" style="border-radius: 25px">
						<v-icon left>mdi-minus</v-icon> 删除
					</v-btn> -->
				</p>
			</v-row>
			<v-row :key="`physical-${i}`" align="center">
				<p v-if="conflict.type != 'physical'">
					<strong>描述关键问题：</strong>
					<InnerTextField @input="change" v-model="physical.keyProblem" />
				</p>
				<p v-if="conflict.type == 'physical'">
					<strong>描述关键问题：</strong>
					<InnerTextField @input="change" v-model="physical.keyProblem" />
				</p>
			</v-row>

			<v-row :key="`conflict-${i}`" align="center">
				<p style="margin-top:-60px;"><strong>写出物理矛盾：</strong></p>
				<ul style="font-size: 20px;">
					<li style="margin-bottom: 10px;">
						<InnerTextField @input="change" v-model="conflict.technicalConflicts[0].c_if" />
						
						 因为
						<InnerTextField @input="change" v-model="conflict.technicalConflicts[0].c_then" />
					</li>
					<li style="margin-bottom: 10px;">但是</li>
					<li style="margin-bottom: 10px;">
						<InnerTextField @input="change" v-model="conflict.technicalConflicts[1].c_if" />
						 因为
						<InnerTextField @input="change" v-model="conflict.technicalConflicts[0].but" />
					</li>
				</ul>
			</v-row>
			<v-row :key="`method-${i}`" align="center">
				<strong>可以通过</strong>
				<new-triz-select v-model="physical.method" :table="false" label="请选择" flat :items="methods" solo
					@change="change"></new-triz-select>
				来解决这个物理矛盾

				<v-btn v-show=" physical.method == '分离矛盾'" dense class="ma-2" tile outlined color="#1976d2" style="border-radius: 25px"
					@click="addKeyWord(physical)">
					<v-icon left>mdi-plus</v-icon> 添加关键词
				</v-btn>
			</v-row>
			<template v-for="(keyWord, k) in physical.keyWords">
				<v-row :key="`physical-conflict-${i}-keyword-${k}`" align="center">
					“
					<new-triz-select label="请选择" v-model="keyWord.keyWord" flat :items="keyWords" solo
						@change="changeKeyWord(physical, keyWord)"></new-triz-select>
					”：{{
            keyWords.find((ele) => ele.value === keyWord.keyWord)
              ? keyWords.find((ele) => ele.value === keyWord.keyWord).resolve
              : ''
          }}
					<v-btn dense class="ma-2" tile outlined color="error" @click="removeKeyWord(physical, k)" style="border-radius: 25px">
						<v-icon left>mdi-minus</v-icon> 删除
					</v-btn>
				</v-row>
			</template>


			<template>
				<v-row v-if="physical.principles && physical.principles.length" :key="`principle-text-${i}`">
					<p><strong>对应的发明原理：</strong></p>
				</v-row>
				<template v-for="(principle, i) in physical.principles">
					<v-row :key="`principle-${i}`">
						<p>（{{ i + 1 }}）{{ principle.value }}.{{ principle.text }}</p>
					</v-row>
					<v-row :key="`principle-detail-${i}`" class="principle_size">
						<ol>
							<!-- <p v-for="(detail,i) in principle.details" :key="detail">{{ detail }}
								<v-icon color="red" @click="question(principle,i)">mdi-chat-question-outline</v-icon>
							</p> -->
							<div v-for="(detail, i) in principle.details" :key="detail" style="width: 980px;display: flex;">
								<p style="width:fit-content;margin-right: 10px;float: left;">{{ detail }}</p>
								<div style="position: relative;width: 400px;flex-grow: 1;">
									<v-icon   color="red" @click="question(principle, i)">mdi-chat-question-outline</v-icon>
									<div v-if="invention.inventiveNumber==principle.value &&  i==invention.inventiveOptionNumber" style="z-index: 999; position:absolute;top: -150px;left: 40px; width: 320px;max-height: 400px;overflow-y: auto;background: rgba(97, 97, 97, 0.9); ">
										<v-img v-for="img in invention.inventiveImage" :key='img' :src="img" style="width: 300px;margin: 10px;"></v-img>
									</div>
								</div>
								
									<!-- <v-tooltip right>
										<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on">
												{{ detail }}
												<v-icon color="red"
													@mousemove="question(principle, i)">mdi-chat-question-outline</v-icon>
											</span>
										</template>
										<span v-if="invention.inventiveImage">123123123
											<v-img v-for="img in invention.inventiveImage" :key='img' :src="img"
												style="width: 300px;margin-bottom: 5px;"></v-img>
										</span>
									</v-tooltip> -->
								
								
							</div>
						</ol>
					</v-row>
				</template>
			</template>
			<template v-for="(solution, j) in physical.solutions">
				<v-row :key="`selectedPrinciple-2-${i}-${j}`">
					<p>
						<template>根据发明原理 {{ solution.selectedPrinciple }} -
							<triz-validator rules="required" id="技术矛盾-关键问题-发明原理选择">
								<new-triz-select label="请选择" :table="false" solo v-model="solution.selectedPrinciple"
									flat :items="physical.principles" @change="change" />
							</triz-validator>
							以及其中的指导原则
							<triz-validator rules="required" id="技术矛盾-关键问题-发明原理子项选择">
								<new-triz-select label="请选择" :table="false" solo
									v-model="solution.selectedPrincipleDetail" flat :items="
                    physical.principles.find((ele) => ele.value === solution.selectedPrinciple)
                      ? physical.principles.find((ele) => ele.value === solution.selectedPrinciple).details
                      : []
                  " @change="change" />
							</triz-validator>
						</template>
						<span>我们得出了方案：</span>

					</p>
				</v-row>
				<v-row :key="`detail-title-2-${i}-${j}`" align="center">
					<strong>基于物理矛盾的解决方案{{ j + 1 }}</strong>
					<v-btn dense class="ma-2" tile outlined color="error" @click="removeSolution(physical, j)" style="border-radius: 25px">
						<v-icon left>mdi-minus</v-icon> 删除
					</v-btn>
				</v-row>
				<v-row :key="`detail-rich-2-${i}-${j}`">
					<v-col style="padding: 12px 12px 12px 0">
						<editor :toref="`reasonsResolves-${j}`" :inputref="`reasonsResolves-${j}Input`"
							toplaceholder='请输入解决方案' v-model="solution.solutionDetail" @blur="change" />
					</v-col>
				</v-row>
			</template>
			<v-row :key="`resolution-2-${i}`">
				<v-btn dense class="ma-2" tile outlined color="#1976d2" @click="addSolution(physical, i)" style="border-radius: 25px">
					<v-icon left>mdi-plus</v-icon> 添加解决方案
				</v-btn>
			</v-row>
		</template>
		<!-- <p>
			<v-btn dense class="ma-2" tile outlined color="#1976d2" @click="addPhysical" style="border-radius: 25px">
				<v-icon left>mdi-plus</v-icon> 添加物理矛盾
			</v-btn>
		</p> -->
	</v-container>
</template>

<script>
	import {register} from '@/api/project'
	import editor from '@/components/editor/editor.vue'
	import InnerTextField from '@/components/new_triz/InnerTextField'
	import {
		physicalConflict,
		keyWords,
		methods
	} from '@/store/default'
	import {
		principles
	} from '@/store/altshuller'
	import Technical from './Technical'

	export default {
		components: {
			InnerTextField,
			editor,
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
			keyProblem: {
				type: Object,
				default: () => ({}),
			},
			conflicts: {
				type: Array,
				default: () => [],
			},
			conflict: {
				type: Object,
				default: () => ({}),
			},
		},
		model: {
			prop: 'triz',
			event: 'change',
		},
		data() {
			return {
				// triz: this.triz,
				physicalConflict,
				keyWords,
				methods,
				principles,
				invention: {},
			}
		},
		computed: {

			techConflicts() {
				const technicalConflicts = this.conflict.technicalConflicts
				const conflict1 = technicalConflicts[0]
				const conflict2 = technicalConflicts[1]
				return `应该${conflict1.c_if}因为${conflict1.c_then}, 应该${conflict2.c_if}因为${conflict2.c_then}`
			},
		},
		mounted() {},
		methods: {
			openInven(){
				this.invention ={}
				console.log(this.invention)
			},
			question(obj,i){
				let _this = this
				register('invention/admin/getInventionByNumber',{
						inventiveNumber: obj.value,
						inventiveOptionNumber: i
					})
					.then((res) => {
						_this.invention = res.data
					})
					.catch(() => {
						
					})
			},
			addPhysical() {
				const id = `physical-${new Date().getTime()}`
				this.conflict.physicalConflicts.push({
					id,
					keyProblem: '',
					conflictDetail: {
						goodA: '',
						goodB: '',
						goodReason: '',
						badA: '',
						badB: '',
						badReason: '',
					},
					keyWords: [],
					principles: [],
					solutions: [],
				})
				this.change()
			},
			removePhysical(index) {
				const physical = this.conflict.physicalConflicts[index]
				physical.solutions.forEach((solution) => {
					this.triz.solutionScores.splice(
						this.triz.solutionScores.findIndex((ele) => ele.id === solution.id),
						1
					)
				})
				this.conflict.physicalConflicts.splice(index, 1)
				this.change()
			},
			addKeyWord(physical) {
				const id = `keyword-${new Date().getTime()}`
				physical.keyWords.push({
					id,
					keyWord: 0,
					keyWordDescription: '',
				})
				this.change()
			},
			removeKeyWord(physical, k) {
				physical.keyWords.splice(k, 1)
				// console.log(physical)
				physical.principles = this.refreshPriciples(physical)
				this.change()
			},
			addSolution(physical) {
				const id = `physical-${new Date().getTime()}`
				physical.solutions.push({
					id,
					selectedPrinciple: '',
					solutionDetail: '',
				})
				this.triz.solutionScores.push({
					id,
				})
				this.change()
			},
			removeSolution(physical, j) {
				const [{
					id
				}] = physical.solutions.splice(j, 1)
				this.triz.solutionScores.splice(
					this.triz.solutionScores.findIndex((ele) => ele.id === id),
					1
				)
				this.change()
			},
			changeKeyWord(physical, keyWord) {
				const item = this.keyWords.find((ele) => ele.value == keyWord.keyWord)
				if (!item) {
					physical.principles = []
					this.change()
					return
				}
				physical.principles = this.refreshPriciples(physical)
				this.change()
			},
			// changeMethod(conflict, method) {
			//   const item = this.methods.find((ele) => ele.value == method.method)
			//   if (!item || !item.suggestion) {
			//     conflict.principles = []
			//   }
			//   conflict.principles = this.refreshPriciples(conflict)
			//   this.change()
			// },
			refreshPriciples(physical) {
				const keyWords = []
				if (physical.method == '分离矛盾') {
					keyWords.push(...physical.keyWords.map((keyWord) => keyWord.keyWord))
				}
				const suggestions = keyWords.flatMap((keyWord) => this.keyWords.find((ele) => ele.value == keyWord)
					.suggestion)
				if (physical.method == '满足矛盾')
					suggestions.push(...this.methods.find((method) => method.value == '满足矛盾').suggestion)
				const principleIds = Array.from(new Set(suggestions))
				console.log('changeKeyWord', principleIds)
				return this.principles.filter((ele) => principleIds.includes(ele.value))
			},
			change() {
				this.$emit('change', this.triz)
			},
		},
	}
</script>
<style lang="scss">
	.principle_size {
		margin-top: 0 !important;

		p {
			font-size: 18px !important;
			text-indent: 30px !important;
			margin-bottom: 0px !important;
			line-height: 20px !important;
		}
	}
</style>