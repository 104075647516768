<template>
	<div>
		<input :ref="inputref" type="file" name="img" accept="image/jpeg,image/jpg,image/png" id="quillInput"
			class="quill-img" multiple="multiple" hidden @change="getImgUrl($event)" />
		<quill-editor class="editor" :ref="toref" v-model="content" :options="editorOption" style="font-size: 20px;"
			@change="onEditorChange(toref)" @blur="onEditorBlur($event, toref)" @focus="onEditorFocus($event, toref)">
		</quill-editor>
	</div>
</template>

<script>
	import {
		updataFilte
	} from "@/api/project";
	import {
		mapGetters
	} from "vuex";
	export default {
		name: 'Editor',
		props: {
			/* 编辑器的内容 */
			value: {
				type: String
			},
			/* input 的ref值 */
			inputref: {
				type: String,
				default: "quillInput"
			},
			/* quill-editor 的ref值 */
			toref: {
				type: String,
				default: "quillEditor"
			},
			/* quill-editor 的placeholder值 */
			toplaceholder: {
				type: String,
				default: "请输入正文..."
			},
			/* quill-editor 的index值 废弃*/
			quillIndex: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				content: this.value,
				editorOption: {
					modules: {
						imageDrop: true,
						imageResize: {
							modules: ["Resize", "DisplaySize"]
						},
						toolbar: {
							container: [
								["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
								["blockquote", "code-block"], // 引用  代码块
								[{
									header: 1
								}, {
									header: 2
								}], // 1、2 级标题
								[{
									list: "ordered"
								}, {
									list: "bullet"
								}], // 有序、无序列表
								[{
									script: "sub"
								}, {
									script: "super"
								}], // 上标/下标
								[{
									indent: "-1"
								}, {
									indent: "+1"
								}], // 缩进
								// [{'direction': 'rtl'}],                         // 文本方向
								[{
									size: ["small", false, "large", "huge"]
								}], // 字体大小
								[{
									header: [1, 2, 3, 4, 5, 6, false]
								}], // 标题
								[{
									color: []
								}, {
									background: []
								}], // 字体颜色、字体背景颜色
								[{
									font: []
								}], // 字体种类
								[{
									align: []
								}], // 对齐方式
								["clean"], // 清除文本格式
								["image"], // 链接、图片、视频
							], //工具菜单栏配置
							handlers: {
								'image': (value) => {
									// console.log(value)
									if (value) {
										this.$refs[this.inputref].click();
									} else {

									}
								}
							},
						}
					},
					// placeholder: this.toplaceholder, //提示
					placeholder: this.toplaceholder, //提示
					readyOnly: false, //是否只读
					theme: "snow", //主题 snow/bubble
					syntax: true, //语法检测
				},
			}
		},
		computed: {
			...mapGetters([
				"HOST"
			]),
		},
		watch: {
			value() {
				this.content = this.value;
			},
		},
		mounted() {
			// console.log('quill对象',this.$refs[this.toref].quill)
			this.$refs[this.toref].quill.options.placeholder=this.toplaceholder
		},
		methods: {
			//聚焦事件  可删除
			onEditorFocus(e, ref) {
				// console.log("foucus", e, ref)
			},
			//失焦事件 可删除
			onEditorBlur(e, ref) {
				// console.log("blur", ref)
				// console.log("inputref", this.inputref)
			},
			onEditorChange(toref) {
				//触发父组件 修改父组件的值
				this.$emit("input", this.content, toref);
			},
			// 失效机理综述中图片上传
			getImgUrl(e) {
				e.stopPropagation();
				if (e.target.files.length > 0) {
					this.getMultipleUrl(e.target.files); //多文件上传
				}
			},
			getMultipleUrl(file) {
				// console.log(file[0])
				// 多文件上传
				for (const element of file) {
					// 处理每个数组元素
					// console.log(element);
					var formData = new FormData();
					formData.append("file", element);
					updataFilte(formData).then((res) => {
						// console.log(res)
						let quill = this.$refs[this.toref].quill; //获取对应的quill对象
						if (res.code == 'OK') {
							let list = res.data;

							let url = list.fileUrl; //拼接图片地址
							// 获取光标所在位置
							let length = quill.selection.savedRange.index;
							// 插入图片  res.url为服务器返回的图片地址
							quill.insertEmbed(length, "image", url);
							// 调整光标到最后
							quill.setSelection(length + 1);
						} else {
							this.$message.error("图片上传失败！")
						}
					}).finally(() => {
						this.$refs[this.inputref].value = null;;
					});
				}

			},
		}
	}
</script>

<style lang="scss" scoped>
	/* 富文本编辑器 修改高度*/
	.editor {
		line-height: normal !important;
		margin-bottom: 0;

	}

	.editor ::v-deep .ql-container {
		min-height: 200px !important;
		max-height: 600px !important;
		overflow: auto;
		font-size: 20px;
		color: black;
	}
</style>
<style>
	/* 修改成中文 */
	.ql-snow .ql-tooltip[data-mode="link"]::before {
		content: "请输入链接地址:";
	}

	.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
		border-right: 0px;
		content: "保存";
		padding-right: 0px;
	}

	.ql-snow .ql-tooltip[data-mode="video"]::before {
		content: "请输入视频地址:";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item::before {
		content: "14px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
		content: "10px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
		content: "18px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
		content: "32px";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item::before {
		content: "文本";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
		content: "标题1";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
		content: "标题2";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
		content: "标题3";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
		content: "标题4";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
		content: "标题5";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
		content: "标题6";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item::before {
		content: "标准字体";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
		content: "衬线字体";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
		content: "等宽字体";
	}
</style>