<template>
	<v-container @click="openInven">
		<v-row align="center" justify="center">
			<ConflictTable v-model="conflict.technicalConflicts" :selected="conflict.selectedConflict"
				@change="change" />
		</v-row>
		<v-row align="center" justify="center"> 表 技术矛盾 </v-row>
		<v-row style="width: 100%">
			<p>
				<strong>确定发明原理：</strong>由于我们目标是解决
				<InnerTextField @input="change" v-model="conflict.slots.slot05" />
				的主要问题，所以选择
				<new-triz-select :table="false" v-model="conflict.selectedConflict" flat solo
					:items="conflict.technicalConflicts?.map((_, i) => ({ text: `技术矛盾${i + 1}`, value: i })) ?? []"
					@change="change" />
				。由阿奇舒勒矛盾矩阵表，可以查到可参考的发明原理如下：
			</p>
		</v-row>
		<v-row align="center" justify="center">
			<AltshullerMartrix :id="`altshuller-martrix-${conflict.id}`"
				:selected-conflict="conflict.technicalConflicts[conflict.selectedConflict]"
				v-model="conflict.principles" @change="changePrinciples" />
		</v-row>
		<v-row align="center" justify="center"> 表 阿奇舒勒矛盾矩阵 </v-row>
		<template v-for="(principle, i) in conflict.principles">
			<v-row :key="`tech-principle-${i}`" class="principle_size2" align="center">
				<p>（{{ i + 1 }}）{{ principle.value }}.{{ principle.text }}</p>
			</v-row>
			<v-row :key="`tech-principle-detail-${i}`" class="principle_size" align="center">
				<ol>
					<div v-for="(detail, i) in principle.details" :key="detail" style="width: 980px;display: flex;">
						<p style="width:fit-content;margin-right: 10px;float: left;">{{ detail }}</p>
						<div style="position: relative;width: 400px;flex-grow: 1;">
							<v-icon   color="red" @click="question(principle, i)">mdi-chat-question-outline</v-icon>
							<div v-if="invention.inventiveNumber==principle.value && i==invention.inventiveOptionNumber" style="z-index: 999; position:absolute;top: -150px;left: 40px; width: 320px;max-height: 400px;overflow-y: auto;background: rgba(97, 97, 97, 0.9); ">
								<v-img v-for="img in invention.inventiveImage" :key='img' :src="img" style="width: 300px;margin: 10px;"></v-img>
							</div>
						</div>
						
							<!-- <v-tooltip right>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										{{ detail }}
										<v-icon color="red"
											@mousemove="question(principle, i)">mdi-chat-question-outline</v-icon>
									</span>
								</template>
								<span v-if="invention.inventiveImage">123123123
									<v-img v-for="img in invention.inventiveImage" :key='img' :src="img"
										style="width: 300px;margin-bottom: 5px;"></v-img>
								</span>
							</v-tooltip> -->
						
						
					</div>
				</ol>
			</v-row>
		</template>
		<template v-for="(solution, j) in conflict.solutions">
			<div :key="`conflict-solutions-${j}`">
				<v-row align="center" style="margin-top: 20px;">
					<strong>基于技术矛盾的解决方案{{ j + 1 }}</strong>
					<v-btn dense class="ma-2" tile outlined color="error" @click="removeSolution(conflict, j)"
						style="border-radius: 25px">
						<v-icon left>mdi-minus</v-icon> 删除
					</v-btn>
				</v-row>
				<v-row align="center">
					<p>
						根据发明原理 {{ solution.selectedPrinciple }} -
						<new-triz-select :table="false" label="请选择" solo v-model="solution.selectedPrinciple" flat
							:items="conflict.principles" @change="change" />
						以及其中的指导原则
						<new-triz-select :table="false" label="请选择" solo v-model="solution.selectedPrincipleDetail" flat
							:items="
                  conflict.principles.find((ele) => ele.value === solution.selectedPrinciple)
                    ? conflict.principles.find((ele) => ele.value === solution.selectedPrinciple).details
                    : []
                " @change="change" />
						我们得出了方案：
					</p>
					
				</v-row>
				<v-row align="center">
					<v-col style="padding: 12px 12px 12px 0">
						<editor :toref="`solutionDetail-${j}`" :inputref="`solutionDetail-${j}Input`" style="width: 100%;"
							toplaceholder='请输入解决方案' v-model="solution.solutionDetail" @blur="change" />
					</v-col>
				</v-row>
			</div>
		</template>
		<v-row>
			<v-btn :disabled="!conflict.principles.length" dense class="ma-2" tile outlined color="#1976d2"
				style="border-radius: 25px" @click="addSolution(conflict)">
				<v-icon left>mdi-plus</v-icon> 添加解决方案
			</v-btn>
		</v-row>



		<v-row :key="`physic`">
			<v-row align="center">
				<strong>转换为物理矛盾</strong>
				<v-btn :key="`change-physic`" v-if="!conflict.isPhysical" dense class="ma-2" tile outlined
					color="orange" style="border-radius: 25px" @click="changeToPhysical(conflict, true)">
					<v-icon left>mdi-refresh</v-icon> 转换到物理矛盾
				</v-btn>
				<v-btn :key="`change-tech`" v-if="conflict.isPhysical" dense class="ma-2" tile outlined color="orange"
					style="border-radius: 25px" @click="changeToPhysical(conflict, false)">
					<v-icon left>mdi-refresh</v-icon> 删除物理矛盾
				</v-btn>
			</v-row>
			<Physical1 v-if="conflict.isPhysical" :conflicts="conflict.technicalConflicts" :triz="triz"
				:conflict="conflict" @change="change" />
		</v-row>
	</v-container>
</template>

<script>
	import {
		register
	} from '@/api/project'
	import ConflictTable from '@/components/new_triz/ConflictTable'
	import editor from '@/components/editor/editor.vue'
	import AltshullerMartrix from '@/components/new_triz/AltshullerMartrix'
	import InnerTextField from '@/components/new_triz/InnerTextField'
	import Physical1 from './Physical1.vue'

	import {
		lineShapes
	} from '@/store/default'

	export default {
		components: {
			ConflictTable,
			editor,
			AltshullerMartrix,
			InnerTextField,
			Physical1,
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
			conflict: {
				type: Object,
				default: () => ({}),
			},
			parentid: {
				type: String,
				default: '',
			}
		},
		model: {
			prop: 'conflict',
			event: 'change',
		},
		data() {
			return {
				lineShapes,
				invention: {},
				
			}
		},
		computed: {

		},
		mounted() {},
		methods: {
			openInven(){
				this.invention ={}
				console.log(this.invention)
			},
			question(obj, i) {
				let _this = this
				register('invention/admin/getInventionByNumber', {
						inventiveNumber: obj.value,
						inventiveOptionNumber: i
					})
					.then((res) => {
						console.log(res.data)
						_this.invention = res.data
					})
					.catch(() => {

					})
			},
			change() {
				this.$emit('change', this.triz)
				this.$nextTick(() => {
					this.$forceUpdate()
				})
			},
			addSolution(conflict) {
				const id = `technical-${new Date().getTime()}`
				conflict.solutions.push({
					id,
					selectedPrinciple: '',
					selectedPrincipleDetail: '',
					solutionDetail: '',
				})
				this.triz.solutionScores.push({
					id,
				})
				this.change()
			},
			removeSolution(conflict, j) {
				const [{
					id
				}] = conflict.solutions.splice(j, 1)
				this.triz.solutionScores.splice(
					this.triz.solutionScores.findIndex((ele) => ele.id === id),
					1
				)
				this.change()
			},
			changeToPhysical(conflict, isPhysical) {
				conflict.isPhysical = isPhysical
				if (isPhysical && conflict.physicalConflicts.length == 0) {
					const id = `physical-${new Date().getTime()}`
					conflict.physicalConflicts.push({
						id,
						keyProblem: '',
						conflictDetail: {
							goodA: '',
							goodB: '',
							goodReason: '',
							badA: '',
							badB: '',
							badReason: '',
						},
						keyWords: [],
						principles: [],
						solutions: [],
					})
				}


				this.change()
			},
			changePrinciples() {
				this.change()
			},
		},
	}
</script>
<style lang="scss">
	.principle_size2 {
		p {
			text-indent: 0 !important;
		}
	}

	.principle_size {
		margin-top: 0 !important;

		p {
			font-size: 18px !important;
			text-indent: 30px !important;
			margin-bottom: 0px !important;
		}
	}

	.margin_select {
		margin: 0 10px;
	}

	// .delete_right{
	//   display: flex;
	//   position: relative;
	//   width: 100%;
	//   .v-btn{
	//     position: absolute;
	//     right: 0;
	//     margin-top: 0px!important;
	//   }
	// }
	.Triz_Select_fujia {
		line-height: 46px !important;

		.v-select__selection {
			text-indent: 10px;
		}
	}
</style>