<template>
	<v-container class="font-size-24">
		<p style="text-indent: 0">（4） 系统功能模型图</p>
		<v-row align="center" justify="center">
			<v-col id="relation-graph-model">
				<RelationGraph :clip="false" :dragable="true" :clickable="false" ref="graph"
					:relation-graph-data="triz.relationGraphData" @graph-data-change="graphDataChange" />
			</v-col>
		</v-row>
		<v-row align="center" justify="center" style="margin-top: 50px;"> 图 功能模型图 </v-row>
		<p style="text-indent: 0">（5） 系统功能缺点</p>
		<v-row justify="center" align="center">
			<IssueTable :nodes="triz.relationGraphData.nodes" :relations="triz.relationGraphData.links"
				@change="issueChange" />
		</v-row>
		<v-row align="center" justify="center"> 表 功能缺点列表 </v-row>
	</v-container>
</template>

<script>
	import RelationGraph from '@/components/new_triz/RelationGraph.vue'
	import IssueTable from '@/components/new_triz/IssueTable.vue'

	export default {
		components: {
			RelationGraph,
			IssueTable,
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
		},
		model: {
			prop: 'triz',
			event: 'change',
		},
		data() {
			return {
				// triz: this.triz,
			}
		},
		computed: {
			locked() {
				return this.$store.state.locked
			},
			relationGraphData() {
				// console.log('triz.relationGraphData',this.triz.relationGraphData)
				let iSno1 = true
				this.triz.relationGraphData.nodes.forEach(e => {
					if (e.data.super && iSno1) {
						e.data.isNo1 = true,
							iSno1 = false
					} else {
						e.data.isNo1 = false
					}
				})
				return this.triz.relationGraphData
			},
		},
		watch: {
			relationGraphData(data) {
				data.nodes.forEach((node) => {
					node.flated = false
				})
				this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.setJsonData(data)
			},
		},
		mounted() {
			this.$nextTick(() => {
				this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.setJsonData(this.triz
					.relationGraphData)
			})
		},
		methods: {
			changeNodeText(node) {
				this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.changeNodeText(node)
			},
			addSystemComponents() {
				const nodes = this.triz.relationGraphData.nodes

				const id = `${new Date().getTime()}`
				const text = ''
				const item = {
					id,
					text,
					data: {
						comp: true
					}
				}

				nodes.push(item)
				this.triz.relations.push(new Array(this.triz.relations.length).fill(0))
				this.triz.relations.forEach((ele) => {
					ele.push(0)
				})
				this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.appendJsonData({
					nodes: [item],
					links: []
				})
			},
			addSuper() {
				const nodes = this.triz.relationGraphData.nodes

				const id = `${new Date().getTime()}`
				const text = ''
				const item = {
					id,
					text,
					data: {
						super: true
					}
				}

				nodes.push(item)
				this.triz.relations.push(new Array(this.triz.relations.length).fill(0))
				this.triz.relations.forEach((ele) => {
					ele.push(0)
				})
				this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.appendJsonData({
					nodes: [item],
					links: []
				})
			},
			removeItem(item) {
				const index = this.triz.relationGraphData.nodes.findIndex((ele) => ele.id === item.id)
				this.triz.relations.splice(index + 1, 1)
				this.triz.relations.forEach((ele) => {
					ele.splice(index + 1, 1)
				})
				this.triz.relationGraphData.nodes.splice(index, 1)
				this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.removeNodeById(item.id)
				this.triz.clips.forEach((clip) => {
					const index = clip.selected.findIndex((select) => select.id === item.id)
					if (index !== -1) {
						clip.selected.splice(index, 1)
					}
				})
			},
			relationChange() {
				console.log('relationChange')
				this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.setJsonData(this.triz.relationGraphData)
			},
			issueChange(relations) {
				this.triz.relationGraphData.links = relations
				this.relationChange()
			},
			addRelation(relation) {
				this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.appendJsonData({
					nodes: [],
					links: [relation]
				})
			},
			removeRelation(relation) {
				this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.removeRelation(relation)
			},
			change() {
				this.$emit('change', this.triz)
			},
			graphDataChange(json) {
				this.triz.relationGraphData = json
				this.$emit('change', this.triz)
			},
		},
	}
</script>