<template>
	<v-container class="font-size-24">
		<template>
			<v-row style="width: 100%;height: 50px;position: absolute;z-index: 999;">
				<v-col cols="10" style="padding: 0px  2px;">
					<v-btn-toggle v-model="btn_toggle" color="primary" variant="outlined">
						<v-btn v-for="(clip, i) in triz.clips" :key="`clips-${i}-btn`">
							<span>裁剪{{i+1}}</span>
						</v-btn>
						<v-icon color="#1976d2" style="width: 25px;height: 25px;margin: 10px 2px;" size="x-large"
							@click="addClip()">mdi-plus-circle-outline</v-icon>
						<v-icon color="#1976d2" style="width: 25px;height: 25px;margin: 10px 2px;" size="x-large"
							@click="removeClip()">mdi-minus-circle-outline</v-icon>
					</v-btn-toggle>
				</v-col>
				<v-col cols="2" style="padding: 0px  2px;" align="center" justify="center">
					<v-btn dense class="ma-2" tile outlined color="#1976d2" style="border-radius: 25px"
						@click="addClipResolve()">
						添加解决方案
					</v-btn>
				</v-col>
			</v-row>

		</template>
		<template v-for="(clip, i) in triz.clips">
			
			<v-row :key="`clip-${i}`" align="center" justify="center" v-show="i==btn_toggle" style="height: 810px;">
				<v-col :id="`relation-graph-clip-${i}`" cols="12">
					<RelationGraph :ref="`clip-${i}`" :dragable="false" :clickable="true"
						:relation-graph-data="triz.relationGraphData" :allowShowMiniToolBar='false'
						@graph-data-change="refresh" :current="clip" @clip-data-change="clipChange" />
				</v-col>
			</v-row>
			<v-row :key="`clip-desc-${i}`" align="center" justify="center" v-show="i==btn_toggle">
				<v-col cols="12" align="center" justify="center">
					图 功能裁剪图
				</v-col>
			</v-row>
			<v-row :key="`clip-table-${i}`" v-show="i==btn_toggle">
				<ClipTable :nodes="triz.relationGraphData.nodes" :relations="triz.relationGraphData.links"
					:current="clip" @change="clipChange" />
			</v-row>
			<v-row :key="`clip-table-desc-${i}`" align="center" justify="center" v-show="i==btn_toggle">
				表 功能裁剪表
			</v-row>
			<v-row :key="`clip-1-${i}`" align="center" justify="center" v-show="i==btn_toggle" style="height: 810px;">
				<v-col :id="`relation-graph-clip-after-${i}`" cols="12">
					<RelationGraph :ref="`clip-after-${i}`" :dragable="false" :clickable="false"
						:relation-graph-data="{ ...triz.relationGraphData }" :allowShowMiniToolBar='false'
						:current="clip" :allClips="triz.clips.filter((ele) => ele.id == clip.id)" :after="true"
						@graph-data-change="refresh" @clip-data-change="clipChange" />
				</v-col>
			</v-row>
			<v-row :key="`clip-1-desc-${i}`" align="center" justify="center" v-show="i==btn_toggle">
				<v-col cols="12" align="center" justify="center">
					图 裁剪后功能图
				</v-col>
			</v-row>
			<!-- 解决方案 -->
			<v-row v-if="clip.clipResolves && i==btn_toggle" v-for="(resolve, j) in clip.clipResolves"
				:key="`clip-resolve-desc-${j}`" align="center">
				<v-col>
					<div :key="`resolve-${j}`">
						<v-row no-gutters align="center">
							
							<strong>基于裁剪{{ i + 1 }}的解决方案{{ j + 1 }}：</strong>
							<v-btn dense class="ma-2" tile outlined color="red" @click="removeClipResolve(j)"
								style="border-radius: 25px">
								<v-icon left>mdi-minus</v-icon> 删除
							</v-btn>
						</v-row>
						<editor :toref="`clipResolves-${i}-${j}`" :inputref="`clipResolves-${i}-${j}Input`" style="width: 100%;"
							toplaceholder='请输入解决方案' v-model="resolve.solutionDetail" @blur="change" />
					</div>
				</v-col>
				
			</v-row>
		</template>
	</v-container>
</template>

<script>
	import RelationGraph from '@/components/new_triz/RelationGraph'
	import ClipTable from '@/components/new_triz/ClipTable'
	import Tinymce from '@/components/Tinymce'
	import HelpIcon from '@/components/HelpIcon'
	import editor from '@/components/editor/editor.vue'
	export default {
		components: {
			RelationGraph,
			ClipTable,
			Tinymce,
			HelpIcon,
			editor
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
		},
		model: {
			prop: 'triz',
			event: 'change',
		},
		data() {
			return {
				btn_toggle: 0,
				clip_dialog:false,
				clipAfter_dialog:false
				// triz: this.triz,
			}
		},
		computed: {
			locked() {
				return this.$store.state.locked
			},
			relationGraphData() {
				return this.triz.relationGraphData
			},
		},
		watch: {
			relationGraphData(data) {
				this.refresh(data)
			},
			btn_toggle() {
				if (this.btn_toggle != undefined && this.$refs[`clip-${this.btn_toggle}`]) {
					this.change()
					this.$refs[`clip-${this.btn_toggle}`][0].setJsonData(this.triz.relationGraphData)
					this.$refs[`clip-after-${this.btn_toggle}`][0].setJsonData(this.triz.relationGraphData)
					// this.$refs[`clip-dialog-${this.btn_toggle}`][0].setJsonData(this.triz.relationGraphData)
					// this.$refs[`clip-dialog-after-${this.btn_toggle}`][0].setJsonData(this.triz.relationGraphData)
				}

			}
		},
		mounted() {
			this.refresh(this.triz.relationGraphData)
			// console.log('mounted_triz', this.triz)
		},
		methods: {
			clipAfterShow(){
				this.$refs[`clip-dialog-${this.btn_toggle}`][0].setJsonData(this.triz.relationGraphData)
				this.clipAfter_dialog=!this.clipAfter_dialog
				
			},
			clipShow(){
				// console.log(this.$refs)
				this.$refs[`clip-dialog-after-${this.btn_toggle}`][0].setJsonData(this.triz.relationGraphData)
				this.clip_dialog=!this.clip_dialog
			},
			
			addClip() {
				this.triz.clips.push({
					id: new Date().getTime(),
					selected: [],
				})
				this.change()
				this.btn_toggle = this.triz.clips.length - 1
			},
			removeClip() {
				const id = this.triz.clips[this.btn_toggle].id
				const issue = this.triz.clips.find((ele) => ele.id === id)
				if (issue) {
					if (this.btn_toggle > 0 && this.triz.clips.length > 0) {
						this.btn_toggle = this.btn_toggle - 1
					} else {
						this.btn_toggle = 0
					}
					const index = this.triz.clips.findIndex((ele) => ele.id === id)
					this.triz.clips.splice(index, 1)
					this.change()
				}
			},
			change() {
				this.$emit('change', this.triz)
				this.refresh(this.triz.relationGraphData)
			},
			refresh(data) {
				this.$nextTick(() => {
					for (let i = 0; i < this.triz.clips.length; i++) {
						this.$refs[`clip-${i}`][0].setJsonData(data)
						// this.$refs[`clip-dialog-${i}`][0].setJsonData(data)
					}
					for (let i = 0; i < this.triz.clips.length; i++) {
						this.$refs[`clip-after-${i}`][0].setJsonData(data)
						// this.$refs[`clip-dialog-after-${i}`][0].setJsonData(data)
					}
				})
			},
			clipChange(current) {
				const target = this.triz.clips.find((clip) => clip.id === current.id)
				target.selected = current.selected
				this.change()
			},
			addClipResolve() {
				const id = `clip-${new Date().getTime()}`
				// this.triz.clipResolves.push({
				// 	id,
				// 	solutionDetail: '',
				// })
				if (this.triz.clips[this.btn_toggle].clipResolves) {
					this.triz.clips[this.btn_toggle].clipResolves.push({
						id,
						solutionDetail: '',
					})
				} else {
					this.triz.clips[this.btn_toggle].clipResolves = [{
						id,
						solutionDetail: '',
					}]
				}

				this.triz.solutionScores.push({
					id,
				})
				this.change()
			},
			removeClipResolve(i) {
				const [{
					id
				}] = this.triz.clips[this.btn_toggle].clipResolves.splice(i, 1)
				this.triz.solutionScores.splice(
					this.triz.solutionScores.findIndex((ele) => ele.id === id),
					1
				)
				this.change()
			},
		},
	}
</script>
<style lang="scss">
	.c-mini-toolbar {
		margin-left: 900px;
		margin-top: 600px;
	}
</style>