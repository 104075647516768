<template>
	<v-container class="font-size-24">
		<p style="text-indent: 0">（2） 相互作用矩阵：根据上述功能结构分析，创建相互作用矩阵如下表所示。</p>
		<v-row justify="center" align="center">
				<RelationTable id="relation-table" :items="[...triz.relationGraphData.nodes]" v-model="triz.relations"
					@relation-changed="relationChange" @related="addRelation" @unrelated="removeRelation"
					:disabled="true" style="margin-right: 12px" />
		</v-row>
		<v-row align="center" justify="center"> 表 相互作用矩阵表 </v-row>
		<v-row>
			<p>
				根据上述元件列表分析各元件之间的相互作用，如两者有相互作用关系，则以“+”标记，后续需要分析具体作用是什么，否则以“-”标记，后续分析中不再考虑，创建相互作用矩阵如上表所示。
			</p>
		</v-row>
	</v-container>
</template>

<script>
	import RelationTable from '@/components/new_triz/RelationTable.vue'

	export default {
		components: {
			RelationTable,
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
		},
		model: {
			prop: 'triz',
			event: 'change',
		},
		data() {
			return {
			}
		},
		computed: {
			relationGraphData() {
				return this.triz.relationGraphData
			},
		},
		watch: {
			relationGraphData(data) {
				data.nodes.forEach((node) => {
					node.flated = false
				})
				this.$emit('change', this.triz)
			},
		},
		mounted() {
		},
		methods: {
			relationChange() {
				this.$emit('change', this.triz)
			},
			addRelation(relation1, relation2) {
				this.triz.relationGraphData.links.push(relation1,relation2)
				// console.log('addRelation',this.triz.relationGraphData.links)
			},
			removeRelation(relation1, relation2) {
				 this.triz.relationGraphData.links=this.triz.relationGraphData.links.filter(link => !((link.from==relation1.from && link.to==relation1.to) || (link.from==relation2.from && link.to==relation2.to)))
				 // console.log('removeRelation',this.triz.relationGraphData.links)
			},
			change(data) {
				this.$emit('change', this.triz)
			},
			graphDataChange(json) {
				this.triz.relationGraphData = json
				this.$emit('change', this.triz)
			},
		},
	}
</script>